
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import SubscriptionTable from '@/components/subscription/SubscriptionTable.vue';
import { namespace } from 'vuex-class';
import { SubscriptionCategory as SC } from '@/interfaces/models/SubscriptionCategories';
import axios from 'axios';

const foodcard = namespace('foodcard');
const venue = namespace('venue');

@Component({
  components: { VWrapper, SubscriptionTable },
})
export default class Subscription extends Vue {
  @foodcard.State('subscriptions') public items!: any;
  @foodcard.State('subscriptionCategories') public subscriptionCategories!: any;
  @venue.Action('fetch') public getVenues!: any;

  @foodcard.Action('fetchSubscriptions') protected getSubscriptions!: any;
  @foodcard.Action('destroySubscription') protected deleteSubscription!: any;
  @foodcard.Action('fetchSubscriptionCategories') protected getSubscriptionCategories!: any;

  public async destroy(subscriptionCategory: SC) {
    await this.deleteSubscription({ _id: subscriptionCategory._id });
  }

  public async mounted() {
    this.$startLoading('subscriptions');
    await this.getSubscriptions();
    await this.getSubscriptionCategories();
    await this.getVenues();
    this.$stopAllLoading();
  }

  // @Watch('venue')
  // public async onVenueChange() {
  //   this.$startLoading('subscriptionCategories');
  //   await this.getSubscriptionCategories({ page: 1 });
  //   this.$stopLoading('subscriptionCategories');
  // }
}
