
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import headers from '@/components/subscriptionCategories/headers';
import { Permission } from '@/enums/Permission';
import adminHeaders from '@/components/subscriptionCategories/adminHeaders';
import { Subscription } from '@/interfaces/models/Subscription';
import { namespace } from 'vuex-class';

const foodcard = namespace('foodcard');

@Component({
  components: { VTable },
})
export default class SubscriptionTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Subscription[];
  @Prop({ type: Number, required: true }) public total!: number;

  @foodcard.Action('setActiveSubscription') protected setActive!: any;

  get headers() {
    if (this.$isAdmin()) {
      return adminHeaders;
    }

    return headers;
  }

  public edit(item: Subscription) {
    this.setActive(item);
    this.$router.push({ name: 'subscription.edit', params: { id: item._id } }).catch();
  }

  public canToggleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.CATEGORY_VISIBLE);
    }
    return this.$can(Permission.CATEGORY_HIDE);
  }
}
